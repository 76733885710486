






































































import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import DictionaryDialog from "@/settings/components/dictionaries/DictionaryDialog.vue";
import { AppSection, Dictionary, DictionaryType } from "@/shared/models";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";

@Component({
  components: { DictionaryDialog },
})
export default class DictionariesView extends mixins(AppSectionAccessMixin) {
  search = "";
  dictionaryDialog = false;
  snackbar = false;

  get type(): string {
    return (
      new Map([
        [AppSection.DICTIONARIES_APP_VERSIONS, DictionaryType.APP_VERSIONS],
        [AppSection.DICTIONARIES_OS_VERSIONS, DictionaryType.OS_VERSIONS],
      ]).get(this.appSection) ?? ""
    );
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  get headers() {
    return [
      {
        text: this.$lang(`appAdmin.dictionaries.${this.type}`),
        align: "start",
        value: "value",
        sortable: false,
      },
    ];
  }

  get items(): Array<Dictionary> {
    return this.$store.state.dictionaryStore[this.type].values;
  }

  @Watch("applicationId", { immediate: true })
  private watchApplicationId() {
    this.loadDictionaries();
  }

  @Watch("type")
  private watchType() {
    this.loadDictionaries();
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("appAdmin.dictionaries.title")
    );
  }

  saveDictionaries(dictionaries: Array<Dictionary>) {
    this.$store.dispatch("saveDictionaries", {
      app: this.applicationId,
      dictionaryType: this.type,
      dictionaries: dictionaries,
      callback: () => (this.snackbar = true),
    });
  }

  loadDictionaries() {
    this.$store.dispatch("loadDictionaries", {
      app: this.applicationId,
      dictionaryTypes: [this.type],
      searchLimit: -1,
    });
  }
}
