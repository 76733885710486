


















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import MultiTextField from "@/shared/components/MultiTextField.vue";
import { Dictionary } from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({
  components: { MultiTextField },
})
export default class DictionaryDialog extends Vue {
  @Prop() dialog = false;
  @Prop() dictionaryType!: string;
  @Prop() applicationId!: string;

  values: Array<string> = [];

  @Watch("dialog", { immediate: true })
  watchDialog(dialog: boolean) {
    if (dialog) {
      this.values = [];
    }
  }

  save() {
    this.$emit(
      "input",
      this.values.map((it) => new Dictionary(it, "", ""))
    );
    this.close();
  }

  close() {
    this.$emit("input:close", false);
  }

  get invalid(): boolean {
    for (let i = 0; i < this.rules.length; i++) {
      for (let j = 0; j < this.values.length; j++) {
        const valid = this.rules[i](this.values[j]);
        if (valid === false || typeof valid === "string") {
          return true;
        }
      }
    }
    return false;
  }

  get rules(): Array<(val: any) => boolean | string> {
    return [
      ValidUtil.required(this.$lang("validation.required")),
      ValidUtil.matchVersion(this.$lang("validation.matchVersion")),
    ];
  }
}
